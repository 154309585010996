.item_imgs{    min-height: 170px;
    max-height:170px;
    display: flex;
    align-items: center;
    border: 1px solid #0003;
    justify-content: center;
    overflow: hidden;
    margin: 0 auto;
    width: 100%;
    border-radius: 25px;}
    .item_imgs img{object-fit: cover;    min-height:170px;
        width: 100%;}
        .fixedprice p{width: unset !important;}
.approvetransaction h1{text-align: center;color: #000; font-weight: 700;font-family: "Roboto";font-size: 18px;margin-top: 20px;}
.unclockpisce{width: unset !important;color: #434242;font-weight: 600;}
.servicefeesec{color: #929292 !important;font-size: 12px !important;font-weight: 300 !important; }
.toggle_custom.switch {
    background-color: initial;
    border: none;
    display: inline-block;
    height: 30px;
    position: relative;
    -webkit-transform: translateY(-10%);
    transform: translateY(-10%);
    width: 60px;
}
.toggle_custom.switch input {
    display: none;
}
.toggle_custom .slider {
    background: #0d6efd;
    border: 4px solid transparent;
    border-radius: 30px;
    bottom: 0;
    cursor: pointer;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;
}
.toggle_custom .overleft {
    background: #00B1FF;
    border: 4px solid transparent;
    border-radius: 30px;
    bottom: 0;
    cursor: pointer;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;
}
.putonsalefixed, .toggle_custom input .putonsalefixed {
    display: none;
}
.approvetransaction .nav-tabs .active{border: 1px solid #0d6efd;    border-radius: 20px;
}
.tabbable-panel .nav-tabs li.active a .fixedprice {
    border: 1px solid #0d6efd;
    color: #000;
}
#putonsale .modal-body{border-bottom-left-radius: 35px;border-bottom-right-radius: 35px;}
.putonsale{display: flex;align-items: baseline;justify-content: space-between;}
.fixedpricedropdownflex p{margin: unset;text-align: left;}
.fixedpriesds{width: 140px !important;}
.fixedprice {
    align-items: center;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 20px;
    box-shadow: 2px 2px 13px 3px #00000036;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 110px;
    width: 131px;

}
.toggle_custom .overleft:before {
    background: #fff;
    border-radius: 30px;
    content: "";
    height: 100%;
    position: absolute;
    -webkit-transform: translateX(2px);
    transform: translateX(2px);
    transition: .4s;
    width: 44%;
}
.toggle_custom .slider:before {
    background: #fff;
    border-radius: 30px;
    content: "";
    height: 100%;
    position: absolute;
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
    transition: .4s;
    width: 50%;
}
.startwalletbtns{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
}
.putonsasle{text-align: left !important; }
 input[type="text"]:focus-visible{outline: none;border-bottom: 1px solid #0d6efd;border-top: none;border-left: none;border-right: none;}
input[type="text"]:focus{box-shadow:none;}
.approvetransaction {margin-top: 20px;}
.approvetransaction h2{font-size: 20px; font-weight: 400;color: #000;}
.approvetransaction h4{font-size: 16px;color: #9d9d9e;margin-top: 10px;}
.approvetransaction .nav-tabs{display: flex;align-items: center;justify-content: center;gap: 30px;border: none;}
.approvetransaction .nav-tabs .fixedprice p {
    margin-bottom: 0;
    padding-top: 20px;
    font-size: 14px;
    font-weight: 700;font-family: "Roboto";margin: 0;
}
.react-select__control{border: none !important;}
.react-select__control--is-focused{border: none !important;}
.formgroupsec label{ font-size: 16px;color: #000;
    font-weight: 700;font-family: "Roboto";}
    .fixedpricedropdownflex input{border: none !important;width: 100%;}
.fixedpricedropdownflex{display: flex !important;align-items: center !important;    justify-content: space-between !important;    border-bottom: 1px solid #0000002e;
}
.fixedpricedropdown  h4{font-size: 16px;color: #9d9d9e;margin-top: 10px;}
.fixedpricedropdown{width: 100%;}
.formgroupsec label{text-align: left;}
a{text-decoration: none;}
.servicefee{display: flex;align-items: center;justify-content: space-between;}
.servicefee h3{font-size: 16px;color: #000;}
.servicefee p{font-size: 14px;color: #9d9d9e;margin: 0;}
textarea { --webit-appearance: none;
    appearance: none;
    border-bottom: 1px solid #9d9d9e;
    border-top: none;
    border-left: none;
    border-right: none;
    width: 100%;
    border-radius: 0;
    }
    textarea:focus-visible{outline: none;border-bottom: 1px solid #0d6efd;border-top: none;border-left: none;border-right: none;}
    textarea:focus{box-shadow:none;}
    .putonsalede{margin-top: 10px;font-size: 14px;color: #9d9d9e;}
    .putonsalede span{font-size: 14px;color: #000;font-weight: 500;}
    @media (max-width:991px){
        #putonsale .modal-dialog {
            max-width: 700px !important;
        }
        
    }
    @media (max-width:575px){
       .item_imgs {
        width: 250px !important;
        }
        #putonsale .modal-content {
            width: 300px !important;
        }.putonsale {
            display: flex;
            align-items: center;
            margin-bottom: 40px;
        }
        .toggle_custom.switch{  -webkit-transform: translateY(-80%);
            transform: translateY(-80%);}
        #putonsale .modal-body .text-left{padding: 0 !important;width: 150px;}
        #putonsale .modal-body{    padding-bottom: 40px;}
        #putonsale .connectwallet{    padding: 5px 40px !important;}
    }